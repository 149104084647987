//import logo from '../logo.svg';
import FirstGold from '../assets/2024_rnd3_PMP-FirstGold.jpg'
import Flex from '../components/Flex'
const Home = () => (
  <Flex justifyContent="center">
    <img 
      src={FirstGold} 
      className="App-logo" 
      alt="2024 MRA Round 3 at PMP - First Gold Finish" 
      style={{
        maxWidth: '80vw'
      }}
    />
  </Flex>
)

export default Home