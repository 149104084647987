import { Fragment } from 'react'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Typography from '../../../components/Typography'
//@TODO define elsewhere and import
const CHAMP_IMG={
  width: '200px',
  height: '285px'
}
const Title = ({title}) => (        
  <Flex justifyContent='center'>
    <Typography variant='h2'>{title}</Typography>
  </Flex>)

const SubSectionTemplate = ({image, title, contents, Override}) => ( Override ? 
(
  <>
    <Title title={title}/>
    <Override/>
  </>
) : (
  <Flex style={{ width: '96vw'}} justifyContent='center'>
    <Flex 
      flexDirection="column" 
      style={{maxWidth: '1388px'}}
      >
      <Title title={title}/>
      <Flex>
        {image ? (
          <Box pr={2}>
            <img 
            src={image} 
            alt="todo" 
            style={CHAMP_IMG}
            />
          </Box>
        ) : null}
        <Box>
          {contents.map(({title, details}, index) => (
            <Fragment key={`${title}-${index}`}>
              {title ? <Typography variant="h4">{title}</Typography> : null}
              <Typography pb={2}>{details}</Typography>
            </Fragment>
          ))}
        </Box>
      </Flex>
    </Flex>
  </Flex>
))

export default SubSectionTemplate