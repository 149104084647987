const makeTime = ({actual, place}) => actual + (place ? ` (#${place})` : '')
const ChampionshipTable = ({title, data}) => (
  <table style={{ padding: 16, width:"100vw", maxWidth: '1200px'}}>
    <thead>
      <tr>
        <th colSpan={data[0].qualify ? 5 : 4}>{title}</th>
      </tr>
      <tr>
        <th>Round - Course</th>
        {data[0].qualify ? <th>Qualified</th> :null}
        <th>Finished</th>
        <th>Fastest Lap (place)</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
      {data.map(({flag, round, qualify, place, time, note}) => (
        <tr 
          key={round+place}
          style={{
            color: flag === 'canceled' ? '#404040' : null
          }}
          >
          <td>{round}</td>
          {data[0].qualify ? <td>{qualify}</td> : null}
          <td>{place || '-'}</td>
          <td>{time ? makeTime(time) : '-'}</td>
          <td>{note || ''}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default ChampionshipTable