import SubSectionTemplate from "../components/SubSectionTemplate"

const points = [
  {
    title: 'Mental Preparation',
    details: 'In my last round of 2024 I identified problematic trends with my progress, more specifically I was attempting to change my race strategy to improve my times. This proved to be counterproductive and I intend to approach my riding more closely to what I was doing at the beginning of 2024.',
  }, {
    title: 'Fitness Training',
    details: 'Through my 2 years of racing, I have found fitness to be one of the major elements that holds me back in the middle of a race. With the ability to race in Race of the Rockies, a 2x sprint race, becoming a new opportunity for me in 2025, endurance is going to play a key role and will need to focus on my fitness for the upcoming season.',
  }, {
    title: 'Additional Coaching',
    details: 'I have received off track coaching throughout my racing career but have not been able to take advantage of professional on track coaching as of yet. Moving into the next level of competition I will be seeking coaching to eliminate bad habits and improve my skills to the next level.',
  }, {
    title: 'Team up with the Mixed-Up Race Team',
    details: 'In the 2024 season I had the pleasure of getting acquainted with members of the Mixed-Up sponsored Race team members. As of current, the intent is to join forces with the other team members so we can motivate, encourage and assist each other with our race programs.',
  }, {
    title: 'New Rider Director',
    details: 'I\'m currently running for the MRA New Rider Director. Given that I am awarded the position, this will allows me to work with new riders helping them identify areas of improvement, while, myself learning new perspectives of racing',
  }
]

const RacePlan = () => (
  <SubSectionTemplate 
    title="2025 Race Plans..."
    contents={points}
  />
)

export default RacePlan