import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Typography from '../../../components/Typography'
import LoCo from '../../../assets/sponsors/LoCo.png'
import ARK from '../../../assets/sponsors/ARK.png'
import DegRacing from '../../../assets/sponsors/64DegRacing.jpg'
import JRC from '../../../assets/sponsors/JRCRaceLeathers.jpg'
import { Link } from 'react-router-dom'

const sponsors = [
  {
    image: DegRacing,
    link: 'https://64degreeracing.com/',
    title: '64 Degree Racing(2023, 2024, 2025)',
  }, {
    image: ARK,
    link: 'https://www.arkmotorsportsco.com/',
    title: 'ARK Motorsports (2024, 2025)',
  }, {
    image: JRC,
    link: 'https://www.facebook.com/profile.php?id=100091492834045',
    title: 'JRC Race Leathers (2024)',
  }, {
    image: LoCo,
    link: 'https://www.locotire.com/',
    title: 'Longmont Tire Company (2024)',
  }
]
const CurrentSponsors = () => (
  <Box>
      <Flex justifyContent='center'>
      <Typography variant='h2'>Current Sponsors</Typography>
    </Flex>
    {sponsors.map(({title, image, link}) => (
      <Box key={title} mb={4}>
        <Flex justifyContent="center">
          <img 
            src={image} 
            alt={title}
            style={{
              height: '200px'
            }}
            />
        </Flex>
        <Flex justifyContent="center">
          <Link to={link} target='_blank'>
            <Typography variant="h5">{title}</Typography>
          </Link>
        </Flex>
      </Box>
    ))}
  </Box>
)

export default CurrentSponsors