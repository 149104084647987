  const ROUND1= 'Rnd 1 - HPR North'
  const ROUND2= 'Rnd 2 - PMP'
  const ROUND3= 'Rnd 3 - PMP'
  const ROUND4= 'Rnd 4 - HPR Full'
  const ROUND5= 'Rnd 5 - HPR West'
  const ROUND6= 'Rnd 6 - HPR Full'
  const ROUND7= 'Rnd 7 - HPR North'
  const ROUND8= 'Rnd 8 - HPR Full (2x Points)'

  const CANCELED = 'MRA officially cancelled this round'

export const amateurGTU = [{
  round: ROUND1,
  place: '2nd',
  time: {
    actual: '1:21:746',
    place: 2,
  },
}, {
  round: ROUND2,
  place: '1st',
  time: {
    actual: '1:37.471',
  },
}, {
  round: ROUND3,
  note: CANCELED,
  flag: 'canceled',
}, {
  round: ROUND4,
  place: '2nd',
  time: {
    actual: '1:56.763',
    place: 1,
  },
}, {
  round: ROUND5,
  place: '17th',
  time: {
    actual: '1:29.918',
    place: 1,
  },
  note: 'Transmission issue',
}, {
  round: ROUND6,
  place: '3rd',
  time: {
    actual: '1:57.618',
    place: 5,
  },
}, {
  round: ROUND7,
  note: CANCELED,
  flag: 'canceled',
}, {
  round: ROUND8,
  place: 'DNF',
  time: {
    actual: 'N/A',
  },
  note: 'Wreck - Collision in first lap',
}]

export const amateurGTO = [{
  round: ROUND1,
  qualify: '5th',
  place: '6th',
  time: {
    actual: '1:22.61',
  },
}, {
  round: ROUND2,
  qualify: '4th',
  place: '2nd',
  time: {
    actual: '1:41.740',
    place: 1,
  },
}, {
  round: ROUND3,
  note: CANCELED,
  flag: 'canceled',
}, {
  round: ROUND4,
  qualify: '1st',
  place: '1st',
  time: {
    actual: '1:54.061',
    place: 1,
  },
}, {
  round: ROUND5,
  qualify: '3rd',
  place: '2nd',
  time: {
    actual: '2:29.544',
    place: 2,
  },
}, {
  round: ROUND6,
  qualify: '8th',
  place: '5th',
  time: {
    actual: '1:57.893',
    place: 5,
  },
}, {
  round: ROUND7,
  note: CANCELED,
  flag: 'canceled',
}, {
  round: ROUND8,
  qualify: '4th',
  place: '6th',
  time: {
    actual: '1:57.620',
  },
}]