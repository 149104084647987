import SubSectionTemplate from "../components/SubSectionTemplate"

const points = [
  {details: 'Though I got my first motorcycle in 2010, I don\'t consider myself a rider until 2021...'},
  {details: 'In 2021 I acquired my ninth bike, one of three Harley Davidsons in my garage - a 2005 Road King. I decided to start focusing on quality riding and started attending a slow speed maneuver group that focused on competing in Motorcycle Rodeos, BucksFunDay. In 2021 I attended my first competition and got 4th place in the novice class. While I enjoyed concentrating on slow speed maneuvers, I still liked to ride on the road and became part of CanyonRiders Association where I often took my 2020 Road glide into the Denver Frontrange mountains. I quickly progressed on my bagger while riding alongside sports bikes in the group until another rider talked me into getting a Aprilia Tuono 660. I continued to progress and was encouraged to try the track in 2021. I enjoyed riding the track, it was a place to focus on dialing in more skills in a safe manner. The friend who had talked me into the Tuono 660 had decided to sell his track prepped RS660 and I snatched it up and continued to attend open lapping. At the end of summer 2022 I tried the MRA\'s (Motorcycle Racing Association) SuperStreet event, a brief chance to get some coaching and the experience of a mock race. I enjoyed the coaching and the experience of the mock race. I decided to sign up for MRA RaceSchool at the beginning of the 2023 season. When I signed up I was looking for additional instruction but wasn\'t convinced that I wanted to race, after the school I bought my license and started prepping for the season, so much so I bought another track bike... '},
  {details: 'I had chatted with my coach from the race school, Mat Muldoon, who mentored me through the season and informed me I had a lot of potential. Before the first round I talked to my Mat and told him I wanted to place top 3 in the Novice class. "You\'ll definitely be in top 10, but you probably won\'t make top 3 with the RS660, it\'s just not as strong as the R6s that you are racing against". Within a week I have an 2015 R6 that was built as a MotoAmerica bike. The bike was fast, and a lot of work to ride compared to the RS, but I was able to be competitive on it. My first ever official race, despite running out of gas on the final corners and having to run the bike across the finish line, I was able to secure 13th starting 19th in the second wave. My second round I was able to move up and finish in 3rd place in the same race. I was racing the RS and the R6 and had received advice from senior riders, "If you want to be good, pick one bike and just ride it". When I made the decision at round 3, I chose the R6, but I ended up having a clutch issue with it resulting in me not being able to finish the race. Trying to repair it myself I went out on it for practice before round 4 and the clutch went out again resulting in me needing to fall back to the RS. For round 5, unfortunately both of my bikes ended up being in the shop and I was unable to participate. I was able to come back round 6 and 7 and compete on the R6 and was able to secure 5th place in the novice championship despite running out of gas my first round, not finishing in round 3 and missing round 4. In addition to that I was able to secure two other 5th places and a 3rd place in classes that I rode my RS660 in.'},
  {details: 'My 2024 season, having learned from my novice year I decided to keep the RS660 as a backup and focus on riding the R6.  Since I placed top 5 in Novice, my 2024 season I moved up to expert, putting me in the Amateur classes. I started the season with expectations of just trying to stay competitive. My first round, first race, Amateur GTO I qualified in fifth, and finished the race in 6th. The next day I ran AmGTU and placed second. I left the round feeling competitive and like I had shaken off some cobwebs. The next round I ran AmGTO with a bad strategy causing me to land in second place, I felt I had 1st in my grasp whenever I was ready to take it but waited too long to make my move. AmGTU I  got my first gold medal ever!  My Third round I got first in AmGTO and second in AmGTU - "Ok I\'m more than competitive, I can run top 3". I stopped running other races for the rest of the season and concentrated my focus and energy on chasing the podium for the Amateur championships. For AmGTU I ended up wrecking out of the last two rounds causing me to drop from third to fourth, and the last round of AmGTO I wrecked in Qualifying resulting in me spending the hours leading up to the race repairing the bike going out tired and not mentally prepared. This resulted in a 6th place and with double points that round I fell down to third place missing second in the championship by two points. '},
  {details: 'The 2024 season finished better than I expected going into it with two top 5 finishes in Amateur class. The season had major ups and downs and lots of valuable lessons from race prep to race craft. With 2024 finished I\'m relieved to be finished chasing championships but have already started prepping for the next season. Here\'s to the 2025 race season!'},
]
const Narrative = () => (
  <SubSectionTemplate 
    title="Narrative" 
    contents={points}
    />
)

export default Narrative
