import Typography from '../../../../components/Typography'
import ChampionshipTable from '../../components/ChampionshipTable'
import { amateurGTU, amateurGTO } from './2024'
import { LW2X, NovGTU, STGTU, Thunder } from './2023'
import SubSectionTemplate from '../../components/SubSectionTemplate'
import Flex from '../../../../components/Flex'
import AmGTO2024 from '../../../../assets/championships/2024-AmGTO.jpg'
import AmGTU2024 from '../../../../assets/championships/2024-AmGTU.jpg'
import LW2X2023 from '../../../../assets/championships/2023-LW2X.jpg'
import Thun2023 from '../../../../assets/championships/2023-Thun.jpg'
import STGTU2023 from '../../../../assets/championships/2023-STGTU.jpg'
import NovGTU2023 from '../../../../assets/championships/2023-NovGTU.jpg'
import Grid from '../../../../components/Grid'
import { CHAMP_IMG } from "../../../../constants/imagesizes"

const Championships = () => (    
  <>
    <Flex justifyContent='center' width="100%">
      <Typography variant="h4">2024 MRA Championships</Typography>
    </Flex>
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={14} md={4} >
        <img 
          src={AmGTO2024} 
          alt="AmGTO2024" 
          style={CHAMP_IMG}
          />
      </Grid>
      <Grid item xs={14} md={10}>
        <ChampionshipTable title="Amateur GTO - 3rd Overall" data={amateurGTO}/>
      </Grid>
    </Grid>
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={14} md={4} >
        <img 
          src={AmGTU2024} 
          alt="AmGTU2024" 
          style={CHAMP_IMG}
          />
      </Grid>
      <Grid item xs={14} md={10}>
        <ChampionshipTable title="Amateur GTU - 4th Overall" data={amateurGTU}/>
      </Grid>
    </Grid>
    <Flex justifyContent='center' width="100%">
      <Typography variant="h4">2023 MRA Championships</Typography>
    </Flex>
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={14} md={4} >
        <img 
          src={STGTU2023} 
          alt="2023 SuperTwins GTU Championship" 
          style={CHAMP_IMG}
          />
      </Grid>
      <Grid item xs={14} md={10}>
        <ChampionshipTable title="SuperTwins GTU - 3rd Overall" data={STGTU}/>
      </Grid>
    </Grid>
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={14} md={4} >
        <img 
          src={NovGTU2023} 
          alt="2023 Novice GTU Championship" 
          style={CHAMP_IMG}
          />
      </Grid>
      <Grid item xs={14} md={10}>
        <ChampionshipTable title="Novice GTU - 5th Overall" data={NovGTU}/>
      </Grid>
    </Grid>
    <Grid container spacing={2} justifyContent='center'>
      <Grid py={4}>
        <img 
          src={Thun2023} 
          alt="2023 Thunderbike Championship" 
          style={CHAMP_IMG}
          />
      </Grid>
      <Grid item xs={14} md={10}>
        <ChampionshipTable title="Thunderbike - 5th Overall" data={Thunder}/>
      </Grid>
    </Grid>
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={14} md={4} >
        <img 
          src={LW2X2023} 
          alt="2023 Lightweight 2x Championship" 
          style={CHAMP_IMG}
          />
      </Grid>
      <Grid item xs={14} md={10}>
        <ChampionshipTable title="Lightweight 2x - 5th Overall" data={LW2X}/>
      </Grid>
    </Grid>
  </>
)
const Highlights = () => (
  <SubSectionTemplate
    title="Highlights"
    Override={Championships}
    />
)

export default Highlights