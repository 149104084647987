import SubSectionTemplate from "../components/SubSectionTemplate"
const points = [{
  title: 'Bike Sponsor Decals',
  details: 'Think of my bike as a moving billboard. Not only will your logo be seen at race weekends, I plaster photos taken from the races on social media. Logos also get displayed on my personal vehicle. Know I appreciate your support!',
}, {
  title: 'Social Media Posts',
  details: 'After each round I do a recap on social media, giving the highs and lows of the weekend and thanking sponsors and everyone helping with my race program',
}, {
  title: 'Podium Announcements',
  details: 'Making it on the podium is hard enough, but when you have to give a race recap right after coming off the track, it\'s hard to remember everything that happens. The last thing I do in my speech is give a shout out to everyone that has helped my race program - it\'s the first thing I think of when I step up on the podium',
}, {
  title: 'Mentorship & New Rider Director',
  details: 'The second best thing I\'ve found in racing is making progress, it is a great feeling! The best feeling is mentoring others and giving them that feeling as well. Part of mentoring is pointing others to the right resources, whether that be equipment or service. I only reach out for sponsorship from companies that provide products and services I believe in and happily encourage others to use those resources as well!',
}, {
  title: 'Professionalism while representing my sponsors',
  details: 'While competition can raise tension I keep in mind I have the option to be there and I\'m there to have fun. I do my best to keep a positive attitude and build relationships with those around me on race weekends. While representing your company as a sponsor I will represent myself in a positive and professional manner.',
}]
const Sponsors = () => (
  <SubSectionTemplate
    title="What I bring to my sponsors..."
    contents={points}
    />
)

export default Sponsors