import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Typography from '../../components/Typography'
import HeaderImg from '../../assets/2024_rnd8_turn13.jpg'

import Bio from './subsections/Bio'
import RacePlan from './subsections/RacePlan'
import Sponsors from './subsections/Sponsors'
import Narrative from './subsections/Narrative'
import Highlights from './subsections/Highlights'
import CurrentSponsors from './subsections/CurrentSponsors'



const TeapotRacing = () => (
  <Box style={{padding:16}}>
    <Flex justifyContent="center">
      <img src={HeaderImg} alt="2024 MRA Round 8 - Turn 13" style={{
        maxWidth: '95vw',
      }}/>
    </Flex>
    <Flex justifyContent='center'>
      <Typography variant='h1'>
        Seth McClaine #418
      </Typography>
    </Flex>
    <Flex justifyContent='center' pb={8}> 
      <Flex justifyContent='space-between' width={800}>
        <Flex justifyContent="center">
          <Typography variant="h5">Erie, Colorado</Typography>
        </Flex>
        <Flex justifyContent="center">
          <Typography variant="h5">SethIMcClaine@GMail.com</Typography>
        </Flex>
      </Flex>
    </Flex>

    <Bio/>
    <Highlights/>
    <RacePlan/>
    <Sponsors/>
    <Narrative/>
    <CurrentSponsors/>
  </Box>
)

export default TeapotRacing