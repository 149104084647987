import Box from '../components/Box'
import Flex from '../components/Flex'
import Link from '../components/Link'
import Typography from '../components/Typography'
// import Banner from '../assets/WhiteWatermarkTransparent.png'

const VARIANT = 'h6'
const HeaderLink = ({href, name}) => (
  <>
    <Typography variant={VARIANT}>
      | 
    </Typography>
    <Link href={href}>
      <Typography variant={VARIANT}>
        {name}
      </Typography>
    </Link> 
    <Typography variant={VARIANT}>
      | 
    </Typography>
  </>
)

const Header = ({routes}) => (
  <>
    <Box sx={{ 
      display: 'flex',
      justifyContent: 'center',
    }}>
      {/* <img src={Banner} alt="logo" style={{
        maxWidth: '80vw',
      }}/> */}
    </Box>
    <Flex sx={{ 
      justifyContent: 'center',
      padding: 8,
      mb: 4,
     }}>
      <Flex sx={{ maxWidth:"800px", width:"100%", justifyContent: 'space-between'}}>
        {routes.filter(({name}) => name !== undefined).map(({path, name}) => (
          <HeaderLink key={`header-${name}`} href={path} name={name}/>
        ))}
      </Flex>
    </Flex>
  </>
)

export default Header