import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import TeapotRacing from "./pages/TeapotRacing";
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const routes = [{
  name: 'Home',
  path: '/',
  element: <Home/>,
}, {
  name: 'TeapotRacing',
  path: '/racing',
  element: <TeapotRacing/>,
}];

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Header routes={routes}/>
        <Routes>
          {routes.map(({name, path, element}) => (
            <Route key={`$route-${name}`} path={path} element={element} />
          ))}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
